import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import dayjs from 'dayjs'
import 'twin.macro'
import 'dayjs/locale/nl'
import Layout from '../components/Layout'
import Container from '../components/Container'
import Header from '../components/Header'
import readTime from '../modules/readTime'
import BlogPostBody from '../components/BlogPostBody'
import Head from '../components/Head'

dayjs.locale('nl')

const Template = ({ data }) => {
  const { markdownRemark: post } = data
  return (
    <Layout>
      <Head title={post.frontmatter.title} />
      <Container>
        <div tw="my-4">
          <Header back="/" hidden={post.frontmatter.status !== 'published'}>
            {post.frontmatter.title}
          </Header>
          <BlogPostBody>{post.html}</BlogPostBody>
          <div tw="mt-6 flex items-center mx-8 md:mx-0">
            <div>
              <div tw="flex space-x-1 text-sm text-gray-500 dark:text-gray-400">
                <time
                  dateTime={dayjs(post.frontmatter.date).format('YYYY-MM-DD')}
                >
                  {dayjs(post.frontmatter.date).format('D MMMM YYYY')}
                </time>
                <span aria-hidden="true">&middot;</span>
                <span>
                  {`leestijd: ${readTime(post.html.split(' ').length, (post.html.match(/<img/g) || []).length)} min`}
                </span>
              </div>
              <div tw="mt-5">
                <a href="/">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    tw="h-5 w-5 inline text-blue-800 dark:text-blue-50"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
                  </svg>
                  <span tw="align-middle text-sm text-blue-900 dark:text-blue-50">Terug naar het overzicht</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </Layout>
  )
}

Template.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.shape({ title: PropTypes.string }),
      html: PropTypes.string,
    }).isRequired,
  }).isRequired,
}

export default Template

export const pageQuery = graphql`
  query BlogPostByPath($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
        status
      }
    }
  }
`
